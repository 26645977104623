export enum PondStatus {
  Active = 'active',
  Completed = 'completed',
}

export enum PondTab {
  Setting = 'cycle',
  Dashboard = 'dashboard',
  Food = 'food',
  Growth = 'growth',
  Water = 'water',
  DeadShrimp = 'dead_shrimp',
  Medicine = 'medicine',
  Cost = 'cost',
  Sales = 'sales',
}

export const Feature = {
  food: 'อาหาร',
  growth: 'การเติบโต',
  waterquality: 'คุณภาพน้ำ',
  deadshrimp: 'กุ้งตาย',
  medicine: 'เวชภัณฑ์',
  shrimpsales: 'ขายกุ้ง',
}

export enum SpeciesType {
  Shrimp = 1,
  Fish = 2,
}

export enum SpeciesName {
  Shrimp = "กุ้ง",
  Fish = "ปลา"
}
